const myFilters = {
  formatterTimeYMDHMS: (dateTime) => {
    let year, month, day, hours, minutes, seconds;
    // const vWeek = ["天", "一", "二", "三", "四", "五", "六"];
    let date = new Date(dateTime);
    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();
    hours = date.getHours();
    minutes = date.getMinutes();
    seconds = date.getSeconds();
    // vWeek_s = date.getDay();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    let currentTime = year + " 年 " + month + " 月 " + day + " 日 " + hours + ":" + minutes + ":" + seconds;
    return currentTime
  }
}

export default myFilters