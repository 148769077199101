import axios from 'axios'
import QS from 'qs'
import { Toast } from 'vant'

//自动切换环境
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = 'http://api.techtimesun.net';
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL == '';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL == '';
// }

//设置超时时间20s
axios.defaults.timeout = 20000;

//设置post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

//对外接口
const axiosMethods = ["get", "post"]
const api = {};
axiosMethods.forEach(method => {
    api[method] = (url, paramsData, Headers) => {
        //重置请求头
        if (Headers) {
            Headers = {
                headers: {
                    "Content-Type": Headers
                }
            };
        }
        if (method == 'get') {
            let data = {
                'params': paramsData
            }
            return get(url, data, Headers)
        } else if (method == 'post') {
            return post(url, paramsData, Headers)
        }
    }
})

//封装get方法
function get(url, paramsData, Headers) {
    return new Promise((resolve, reject) => {
        axios.get(url, paramsData, Headers).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.log(err)
            Toast.fail('请求失败')
            reject(err.data)
        })
    })
}

//封装post方法
function post(url, paramsData, Headers) {
    // console.log(Headers)
    return new Promise((resolve, reject) => {
        //如有传请求头，对paramsData进行处理
        if (Headers) {
            if (Headers.headers["Content-Type"] == "application/x-www-form-urlencoded") {
                paramsData = QS.stringify(paramsData)
            } else if (Headers.headers["Content-Type"] == "application/json") {
                paramsData = JSON.stringify(paramsData)
            }
        }
        axios.post(url, paramsData, Headers).then(res => {
            resolve(res.data)
        }).catch(err => {
            Toast.fail('请求失败')
            reject(err.data)
        })
    })
}

//请求拦截（请求发出前处理请求）
axios.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})

//响应拦截 （处理响应数据）
axios.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status) {
        Toast('服务器异常');
    }
    return Promise.reject(error.response)
})

export default api