import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'm'
  },
  {
    path: '/test',
    name: 'test',
    component: ()=> import('@/views/test'),
    meta:{
      title:'测试'
    }
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/m',
    name: 'model',
    component: ()=> import('@/views/model'),
    meta:{
      title:'模型'
    },
    children:[
      {
        path: '/model/scan',
        name: 'scanPage',
        component: ()=> import('@/views/scanPage'),
        meta:{
          title:'扫描二维码'
        },
      },
      {
        path: '/model/appScan',
        name: 'appScan',
        component: ()=> import('@/views/appScan'),
        meta:{
          title:'扫描二维码'
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/login'),
    meta:{
      title:'登录'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: ()=> import('@/views/order'),
    meta:{
      title:'订单界面'
    }
  },
  {
    path: '/work',
    name: 'work1',
    component: ()=> import('@/views/work'),
    meta:{
      title:'工作界面'
    },
    children:[]
  }
]

const router = new VueRouter({
  routes
})

export default router