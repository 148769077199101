import {
  Slider,
  Toast,
  Button,
  Field,
  Dialog,
  Popup,
  Uploader  
} from 'vant'

const components = {
  Slider,
  Toast,
  Button,
  Field,
  Dialog,
  Popup,
  Uploader  
}
const componentsHandler = {
  install(Vue) {
    Object.keys(components).forEach(key => Vue.use(components[key]))
  }
}
export default componentsHandler