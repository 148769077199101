<template>
  <div class="wrapper">
    <div class="model" ref="model"></div>
    <div class="handle">
      <div class="slide">
        <div class="text">
          <span>滑动旋转</span>
          <div class="resetICon" @click="resetSlideValue">
            <i
              class="iconfont iconrecovery"
              :class="{ active: showActive }"
              ref="reset"
            ></i>
          </div>
        </div>
        <van-slider
          v-model="value"
          bar-height="4px"
          active-color="#009688"
          @input="onChange"
        />
      </div>
      <van-button class="myBtn" block @click="changeModelState">{{
        modelText
      }}</van-button>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
// import { GUI } from "three/examples/jsm/libs/dat.gui.module.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TeapotBufferGeometry } from "three/examples/jsm/geometries/TeapotBufferGeometry.js";
let camera, scene, renderer;
let cameraControls;
let effectController;
const teapotSize = 400;
let ambientLight, light;

let tess = -1; // force initialization
let bBottom;
let bLid;
let bBody;
let bFitLid;
let bNonBlinn;
let shading;
let wireMaterial, phongMaterial;
let box;
let group;

let teapot;

export default {
  name: "Home",
  data() {
    return {
      diffuseColor: undefined,
      specularColor: undefined,
      value: 0,
      defaultValue: 54,
      modelText: "线框",
      showActive: false,
      meshList: [],
    };
  },
  mounted() {
    this.diffuseColor = new THREE.Color();
    this.specularColor = new THREE.Color();
    this.init();
    this.render();
  },
  methods: {
    //slide滚动条滚动
    onChange(value) {
      const axis = new THREE.Vector3(0, 1, 0);
      // axis.normalize();
      const radian = (2 * Math.PI) / 100;
      let box3D = new THREE.Box3();
      box3D.setFromObject(group);
      console.log(box3D.getCenter())
      group.setRotationFromAxisAngle(axis, -radian * value); //三维向量、弧度
      box.update()
      this.render();
    },

    //重置slide值
    resetSlideValue: function () {
      this.showActive = true;
      setTimeout(() => {
        this.showActive = false;
      }, 500);
      this.value = this.defaultValue;
      this.onChange(this.value);
    },
    //改变模型形态
    changeModelState: function () {
      console.log(effectController);
      const newShading = effectController.newShading;
      if (newShading === "glossy") {
        effectController.newShading = "wireframe";
        this.modelText = "线框";
      } else {
        effectController.newShading = "glossy";
        this.modelText = "实体";
      }
      this.render();
    },

    init: function () {
      const container = this.$refs.model;
      const canvasWidth = this.$refs.model.offsetWidth;
      const canvasHeight = this.$refs.model.offsetHeight;
      // CAMERA
      camera = new THREE.PerspectiveCamera(
        45,
        canvasWidth / canvasHeight,
        1,
        80000
      );
      camera.position.set(0, 3200, 2500); //设置相机默认位置

      // LIGHTS
      ambientLight = new THREE.AmbientLight(0x333333); // 0.2

      light = new THREE.DirectionalLight(0xffffff, 1.0);
      // direction is set in GUI

      // RENDERER
      renderer = new THREE.WebGLRenderer({ antialias: true });//antialias是否执行抗锯齿
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(canvasWidth, canvasHeight);
      renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(renderer.domElement);

      // EVENTS
      window.addEventListener("resize", this.onWindowResize, false);

      // CONTROLS
      cameraControls = new OrbitControls(camera, renderer.domElement);
      console.log(cameraControls);
      cameraControls.enableRotate = true; //禁止上下左右旋转
      cameraControls.addEventListener("change", this.render);

      // MATERIALS
      const materialColor = new THREE.Color();
      materialColor.setRGB(1.0, 1.0, 1.0);

      wireMaterial = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        wireframe: true,
      });
      phongMaterial = new THREE.MeshPhongMaterial({
        color: materialColor,
        side: THREE.DoubleSide,
      });

      // scene itself
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xaaaaaa);
      scene.add(ambientLight);
      scene.add(light);

      // GUI
      this.setupGui();
    },

    // EVENT HANDLERS
    onWindowResize: function () {
      const canvasWidth = window.innerWidth;
      const canvasHeight = window.innerHeight;

      renderer.setSize(canvasWidth, canvasHeight);

      camera.aspect = canvasWidth / canvasHeight;
      camera.updateProjectionMatrix();
      this.render();
    },

    setupGui: function () {
      effectController = {
        shininess: 40.0,
        ka: 0.17,
        kd: 0.51,
        ks: 0.2,
        metallic: true,
        hue: 0.121,
        saturation: 0.73,
        lightness: 0.66,
        lhue: 0.04,
        lsaturation: 0.01, // non-zero so that fractions will be shown
        llightness: 1.0,
        lx: 0.32,
        ly: 0.39,
        lz: 0.7,
        newTess: 15,
        bottom: true,
        lid: true,
        body: true,
        fitLid: false,
        nonblinn: false,
        newShading: "glossy",
      };
    },
    render: function () {
      if (
        effectController.newTess !== tess ||
        effectController.bottom !== bBottom ||
        effectController.lid !== bLid ||
        effectController.body !== bBody ||
        effectController.fitLid !== bFitLid ||
        effectController.nonblinn !== bNonBlinn ||
        effectController.newShading !== shading
      ) {
        tess = effectController.newTess;
        bBottom = effectController.bottom;
        bLid = effectController.lid;
        bBody = effectController.body;
        bFitLid = effectController.fitLid;
        bNonBlinn = effectController.nonblinn;
        shading = effectController.newShading;
        this.createNewTeapot();
      }
      phongMaterial.shininess = effectController.shininess;
      this.diffuseColor.setHSL(
        effectController.hue,
        effectController.saturation,
        effectController.lightness
      );
      if (effectController.metallic) {
        // make colors match to give a more metallic look
        this.specularColor.copy(this.diffuseColor);
      } else {
        // more of a plastic look
        this.specularColor.setRGB(1, 1, 1);
      }
      this.diffuseColor.multiplyScalar(effectController.kd);
      phongMaterial.color.copy(this.diffuseColor);
      this.specularColor.multiplyScalar(effectController.ks);
      phongMaterial.specular.copy(this.specularColor);
      ambientLight.color.setHSL(
        effectController.hue,
        effectController.saturation,
        effectController.lightness * effectController.ka
      );

      light.position.set(
        effectController.lx,
        effectController.ly,
        effectController.lz
      );
      light.color.setHSL(
        effectController.lhue,
        effectController.lsaturation,
        effectController.llightness
      );
      scene.background = null;

      renderer.render(scene, camera);
    },

    createNewTeapot: function () {
      if (teapot !== undefined) {
        teapot.geometry.dispose();
        console.log(teapot)
        scene.remove(teapot);
      }

      const teapotGeometry = new TeapotBufferGeometry(
        teapotSize,
        tess,
        effectController.bottom,
        effectController.lid,
        effectController.body,
        effectController.fitLid,
        !effectController.nonblinn
      );

      teapot = new THREE.Mesh(
        teapotGeometry,
        shading === "wireframe" ? wireMaterial : phongMaterial
      );

      // if (this.value !== 0) {
      //   const axis = new THREE.Vector3(0, 1, 0);
      //   const radian = (2 * Math.PI) / 100;
      //   teapot.setRotationFromAxisAngle(axis, -radian * this.value); //三维向量、弧度
      // }
      group = new THREE.Group();
      group.add(teapot)
      box = new THREE.BoxHelper( teapot, 0xffff00 );
      scene.add(box)
      scene.add(group);
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  .model {
    height: 80%;
  }
  .handle {
    height: 20%;
    background-color: #000000;
    padding: 0 5%;
    box-sizing: border-box;
    .slide {
      color: #ffffff;
      margin-bottom: 50px;
      .text {
        font-size: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .resetICon {
          padding: 4px 6px 4px 20px;
        }
        i {
          display: inline-block;
          transform: rotate(0deg);
          transition: all 1s ease;
          @keyframes myRotate {
            from {
              transform: rotate(0);
            }
            to {
              transform: rotate(360deg);
            }
          }
          &.active {
            animation: myRotate 1s ease;
          }
        }
      }
    }
    .myBtn {
      font-size: 30px;
      color: #ffffff;
      border-radius: 20px;
      background-color: #009688;
      border-color: #81dad2;
    }
  }
}
</style>
