import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vantComponents from './assets/js/vant.config.js' //vantUI按需引入
import 'lib-flexible/flexible.js'
import '@/assets/css/reset.less'
import myFilters from '@/assets/js/filters.js'
import Api from '@/assets/js/request.js'
// import Back from './assets/js/Back' //打包apk时要引入

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.use(vantComponents);
Vue.prototype.$api = Api; //定义全局引用
Vue.prototype.Host = ''; //apk（https://panelscan-test.getgeekfun.cn'），本地测线上数据（/api），发布H5传空
Vue.prototype.onLine = false; //apk时为true
Vue.prototype.testLine = true; //本地测试线上数据
Vue.config.productionTip = false

//全局添加过滤器
Object.keys(myFilters).forEach(key => {
    Vue.filter(key, myFilters[key])
})


//路由守卫
router.beforeEach((to, from, next) => {
    //浏览器标题栏
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next();
})
new Vue({
    // Back, //打包apk时要引入
    router,
    render: h => h(App)
}).$mount('#app')